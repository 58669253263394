import React, { useLayoutEffect, useRef, useState } from "react";
import { Button } from "monday-ui-react-core";
import shortid from "shortid";

import styles from "./recordcamera.module.css";

import { CountDown } from "../countdown";
import { Timer } from "../timer";
import { Recorder, saveBlob } from "../../utils";
import { CloseButton } from "../closebutton";
import { Preview } from "../preview";

export function RecordCamera({ onClose, onProgress }) {
  const [isRecording, setIsRecording] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const recorder = useRef(null);
  const videoId = useRef(null);

  const previewRef = useRef(null);

  useLayoutEffect(() => {
    recorder.current = new Recorder({ camera: true });
    recorder.current.init().then((stream) => {
      previewRef.current.srcObject = stream;
      previewRef.current.play();
    });
    recorder.current.onStop = async (blob) => {
      videoId.current = shortid.generate();
      await saveBlob(videoId.current, blob);
      setIsRecording(false);
    };
  }, []);
  return (
    <div className={styles.recordcamera}>
      <CloseButton onClick={onClose} />
      {!videoId.current && (
        <video ref={previewRef} muted className={styles.preview} />
      )}
      <div className={styles.actions}>
        {isRecording && (
          <div style={{ marginBottom: "8px" }}>
            <Timer />
          </div>
        )}
        {!isRecording && !videoId.current && (
          <button
            onClick={() => {
              recorder.current.start();
              setIsRecording(true);
            }}
            className={styles.button}
          >
            Start Recording
          </button>
        )}
        {isRecording && !videoId.current && (
          <button
            onClick={() => {
              recorder.current.stop();
            }}
            className={styles.button}
          >
            Stop Recording
          </button>
        )}
        {videoId.current && (
          <Preview
            id={videoId.current}
            type="video"
            filename="video.mp4"
            successMessage="Successfully uploaded video"
            onProgress={onProgress}
            onComplete={onClose}
          />
        )}
      </div>
    </div>
  );
}
