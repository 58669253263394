import React, { useEffect, useState } from "react";

import styles from "./timer.module.css";

function formatTime(sec) {
  return `${Math.floor(sec / 60)
    .toString()
    .padStart(2, "0")}:${(sec % 60).toString().padStart(2, "0")}`;
}

export function Timer({ max = 300, onEnd = () => {} }) {
  const [time, setTime] = useState("00:00");
  useEffect(() => {
    let time = 0;
    const timer = setInterval(() => {
      time++;
      if (time > max) {
        clearInterval(timer);
        return onEnd();
      }
      setTime(formatTime(time));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return <span className={styles.timer}>{time}</span>;
}
