import React, { useRef, useState } from "react";
import { Tooltip } from "monday-ui-react-core";

import styles from "./toolbar.module.css";

import { ReactComponent as Screen } from "./screen.svg";
import { ReactComponent as ScreenRecord } from "./screen-record.svg";
import { ReactComponent as Camera } from "./camera.svg";
import { RecordScreen } from "../recordscreen";
import { CaptureScreen } from "../capturescreen";
import { RecordCamera } from "../recordcamera";

export function Option({ icon, title, subtitle, onClick }) {
  return (
    <button onClick={onClick}>
      <div>{icon}</div>
      <div>
        <span className={styles.title}>{title}</span>
        <span className={styles.subtitle}>{subtitle}</span>
      </div>
    </button>
  );
}

export function Toolbar({}) {
  const [currentOption, setCurrentOption] = useState(null);
  const [progress, setProgress] = useState("");
  return (
    <>
      <div className={styles.toolbar}>
        <Option
          icon={<Screen />}
          title="Take Screenshot"
          subtitle="Take a screenshot of your desktop / window / tab"
          onClick={async () => {
            setCurrentOption("capturescreen");
          }}
        />
        <Option
          icon={<ScreenRecord />}
          title="Record Screen"
          subtitle="Record a video of your desktop / window / tab"
          onClick={async () => {
            setCurrentOption("recordscreen");
          }}
        />
        <Option
          icon={<Camera />}
          title="Record Video"
          subtitle="Record video from your webcam"
          onClick={async () => {
            setCurrentOption("recordcamera");
          }}
        />
        {currentOption === "capturescreen" && (
          <CaptureScreen
            onClose={() => {
              setCurrentOption(null);
              setProgress("");
            }}
            onProgress={(progress) => {
              setProgress(progress);
            }}
          />
        )}
        {currentOption === "recordscreen" && (
          <RecordScreen
            onClose={() => {
              setCurrentOption(null);
              setProgress("");
            }}
            onProgress={(progress) => {
              setProgress(progress);
            }}
          />
        )}
        {currentOption === "recordcamera" && (
          <RecordCamera
            onClose={() => {
              setCurrentOption(null);
              setProgress("");
            }}
            onProgress={(progress) => {
              setProgress(progress);
            }}
          />
        )}
        {progress && (
          <div className={styles.loading}>
            <svg
              width="48"
              height="48"
              viewBox="0 0 135 140"
              xmlns="http://www.w3.org/2000/svg"
              fill="#fff"
            >
              <rect y="10" width="15" height="120" rx="6">
                <animate
                  attributeName="height"
                  begin="0.5s"
                  dur="1s"
                  values="120;110;100;90;80;70;60;50;40;140;120"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="y"
                  begin="0.5s"
                  dur="1s"
                  values="10;15;20;25;30;35;40;45;50;0;10"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
              </rect>
              <rect x="30" y="10" width="15" height="120" rx="6">
                <animate
                  attributeName="height"
                  begin="0.25s"
                  dur="1s"
                  values="120;110;100;90;80;70;60;50;40;140;120"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="y"
                  begin="0.25s"
                  dur="1s"
                  values="10;15;20;25;30;35;40;45;50;0;10"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
              </rect>
              <rect x="60" width="15" height="140" rx="6">
                <animate
                  attributeName="height"
                  begin="0s"
                  dur="1s"
                  values="120;110;100;90;80;70;60;50;40;140;120"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="y"
                  begin="0s"
                  dur="1s"
                  values="10;15;20;25;30;35;40;45;50;0;10"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
              </rect>
              <rect x="90" y="10" width="15" height="120" rx="6">
                <animate
                  attributeName="height"
                  begin="0.25s"
                  dur="1s"
                  values="120;110;100;90;80;70;60;50;40;140;120"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="y"
                  begin="0.25s"
                  dur="1s"
                  values="10;15;20;25;30;35;40;45;50;0;10"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
              </rect>
              <rect x="120" y="10" width="15" height="120" rx="6">
                <animate
                  attributeName="height"
                  begin="0.5s"
                  dur="1s"
                  values="120;110;100;90;80;70;60;50;40;140;120"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="y"
                  begin="0.5s"
                  dur="1s"
                  values="10;15;20;25;30;35;40;45;50;0;10"
                  calcMode="linear"
                  repeatCount="indefinite"
                />
              </rect>
            </svg>
            {progress}
          </div>
        )}
      </div>
    </>
  );
}
