import React, { useEffect, useRef, useState } from "react";
import { Button, TextField, Loader } from "monday-ui-react-core";
import shortid from "shortid";

import styles from "./recordscreen.module.css";

import { CloseButton } from "../closebutton";
import { CountDown } from "../countdown";
import { Timer } from "../timer";
import { Recorder, saveBlob, uploadFile, readBlob } from "../../utils";
import { Preview } from "../preview";

export function RecordScreen({ onClose, onProgress }) {
  const [showCountdown, setShowCountdown] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const videoId = useRef(null);
  const recorder = useRef(null);
  const [processing, setProcessing] = useState(false);
  useEffect(() => {
    try {
      recorder.current = new Recorder({ screen: true });
      recorder.current
        .init()
        .then(() => {
          setShowCountdown(true);
        })
        .catch((ex) => {
          onClose();
        });
      recorder.current.onStop = async (blob) => {
        setIsRecording(false);
        setProcessing(true);
        videoId.current = shortid.generate();
        await saveBlob(videoId.current, blob);
        setPreviewUrl(URL.createObjectURL(blob));
        setProcessing(false);
      };
    } catch (ex) {
      onClose();
    }
  }, []);
  return (
    <div className={styles.recordscreen}>
      <CloseButton onClick={onClose} />
      {showCountdown && (
        <CountDown
          onComplete={() => {
            setIsRecording(true);
            recorder.current.start();
          }}
        />
      )}
      {isRecording && (
        <div className={styles.timer}>
          <Timer
            onEnd={() => {
              recorder.current.stop();
            }}
          />
          <div>
            <button
              onClick={() => {
                recorder.current.stop();
              }}
            >
              Stop Recording
            </button>
          </div>
        </div>
      )}
      {processing && (
        <div style={{ width: "30px", height: "30px" }}>
          <Loader svgClassName="loader-size-sm" />
        </div>
      )}
      {previewUrl && (
        <Preview
          id={videoId.current}
          type="video"
          filename="recording.mp4"
          successMessage="Successfully uploaded screen recording"
          onProgress={onProgress}
          onComplete={onClose}
        />
      )}
    </div>
  );
}
