import React from "react";

export function Docs({ showCitation = false }) {
  return (
    <div className="help">
      <span className="chip">Help</span>
      <br />
      <br />
      <iframe
        width="480"
        height="270"
        src="https://www.youtube.com/embed/5gk9W4rZgeg"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <h2>Capturing Screenshot</h2>
      <p>
        You can take screenshots of your desktop / window / tab. The app will
        first ask you to select the screen. You will have a countdown timer
        3..2..1.. until the screenshot is taken giving you enough time for you
        to get ready. You can also edit the screenshot image before saving.
      </p>
      <p>
        <iframe
          width="480"
          height="270"
          src="https://www.youtube.com/embed/rcth8aMC0us"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </p>
      <h2>Recording Screen</h2>
      <p>
        You can record your desktop / window / tab. The app will first ask you
        to select the screen. You will have a countdown timer 3..2..1.. until
        you are ready to start the recording. You can stop the recording either
        from the stop recording prompt available from browser or here. Option to
        edit the video file from the browser itself before saving is coming
        soon.
      </p>
      <p>
        <iframe
          width="480"
          height="270"
          src="https://www.youtube.com/embed/_vYyvTW7_8s"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </p>
      <h2>Record Your Camera</h2>
      <p>
        You can record your webcam + mic. You can see the preview of what is
        being recorded now. Option to edit the video file from the browser
        itself before saving is coming soon.
      </p>
      <hr />
      {showCitation && (
        <div>
          Icons made by{" "}
          <a
            href="https://www.flaticon.com/authors/flat-icons"
            title="Flat Icons"
            target="_blank"
            rel="noreferrer"
          >
            Flat Icons
          </a>{" "}
          from{" "}
          <a
            href="https://www.flaticon.com/"
            title="Flaticon"
            target="_blank"
            rel="noreferrer"
          >
            www.flaticon.com
          </a>
        </div>
      )}
    </div>
  );
}
