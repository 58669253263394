import React, { useEffect, useRef, useState } from "react";
import { Button, TextField, Loader } from "monday-ui-react-core";
import shortid from "shortid";

import styles from "./capturescreen.module.css";

import { CountDown } from "../countdown";
import { Timer } from "../timer";
import { Recorder, saveBlob, readBlob, uploadFile } from "../../utils";
import { CloseButton } from "../closebutton";
import { Preview } from "../preview";

export function CaptureScreen({ onClose, onProgress }) {
  const [showCountdown, setShowCountdown] = useState(false);
  const imageId = useRef(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [processing, setProcessing] = useState(false);
  const recorder = useRef(null);
  useEffect(() => {
    recorder.current = new Recorder({ screen: true });
    recorder.current
      .init()
      .then(() => {
        setShowCountdown(true);
      })
      .catch((ex) => {
        onClose();
      });
  }, []);
  return (
    <div className={styles.capturescreen}>
      <CloseButton onClick={onClose} />
      {showCountdown && (
        <CountDown
          onComplete={async () => {
            setShowCountdown(false);
            setProcessing(true);
            const blob = await recorder.current.capture();
            imageId.current = shortid.generate();
            await saveBlob(imageId.current, blob);
            setProcessing(false);
            setPreviewUrl(URL.createObjectURL(blob));
          }}
        />
      )}
      {processing && (
        <div style={{ width: "30px", height: "30px" }}>
          <Loader svgClassName="loader-size-sm" />
        </div>
      )}
      {previewUrl && (
        <Preview
          id={imageId.current}
          type="image"
          filename="screenshot.png"
          successMessage="Successfully uploaded screenshot"
          onProgress={onProgress}
          onComplete={onClose}
        />
      )}
    </div>
  );
}
