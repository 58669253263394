import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ImageEditor from "filerobot-image-editor";

import { readBlob, blobToDataURI, saveDataURI } from "../../utils";

import { CONFIG } from "./config";

import "./edit-image.css";

export function EditImage() {
  // const src = "https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg";
  const { imageId } = useParams();
  const [src, setSrc] = useState(null);
  useEffect(() => {
    readBlob(imageId)
      .then((blob) => {
        return blobToDataURI(blob);
      })
      .then((uri) => setSrc(uri));
  }, [imageId]);
  return (
    <div className="edit-image">
      {src && (
        <>
          <ImageEditor
            src={src}
            show={true}
            showInModal={false}
            finishButtonLabel="Save"
            config={{
              translations: CONFIG,
              showInModal: false,
            }}
          />
          <button
            className={"save-image"}
            onClick={async () => {
              const canvas = document.querySelector(".filerobot-edit-canvas");
              await saveDataURI(imageId, canvas.toDataURL("image/png"));
              window.close();
            }}
          >
            Save
          </button>
        </>
      )}
    </div>
  );
}
