import mondaySdk from "monday-sdk-js";
import Dexie from "dexie";
// import "dexie-observable";

export const db = new Dexie("csDB");
db.version(1).stores({
  files: `id, file`,
});

const monday = mondaySdk();

MediaStream.prototype.stop = function () {
  this.getTracks().forEach((track) => {
    track.stop();
  });
};

export class Recorder {
  constructor({ screen, camera }) {
    this.screen = screen;
    this.camera = camera;
  }

  async init() {
    this.chunks = [];
    if (this.screen) {
      const tracks = [];
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        cursor: "always",
      });
      tracks.push(screenStream.getVideoTracks()[0]);
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        tracks.push(audioStream.getAudioTracks()[0]);
      } catch {}
      this.stream = new MediaStream(tracks);
    } else {
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
    }
    return this.stream;
  }

  async capture() {
    return new Promise((resolve, reject) => {
      const videoEle = document.createElement("video");
      videoEle.style.display = "none";
      videoEle.muted = true;
      videoEle.srcObject = this.stream;
      videoEle.oncanplaythrough = () => {
        const canvas = document.createElement("canvas");
        canvas.style.display = "none";
        document.body.appendChild(canvas);
        canvas.setAttribute("width", videoEle.videoWidth);
        canvas.setAttribute("height", videoEle.videoHeight);
        const context = canvas.getContext("2d");
        //   context.fillStyle = "#000";
        context.drawImage(
          videoEle,
          0,
          0,
          videoEle.videoWidth,
          videoEle.videoHeight
        );

        this.stream.stop();

        const imageData = canvas.toDataURL("image/png");

        resolve(dataURItoBlob(imageData));
      };
      document.body.appendChild(videoEle);
      videoEle.play();
    });
  }

  async start() {
    this.mediaRecorder = new MediaRecorder(this.stream);
    this.mediaRecorder.ondataavailable = (e) => {
      this.chunks.push(e.data);
    };
    this.mediaRecorder.onstop = () => {
      this.stream.stop();
      const blob = new Blob(this.chunks, { type: "video/mp4" });
      this.chunks.length = 0;
      this.mediaRecorder = null;
      this.onStop(blob);
    };
    this.mediaRecorder.start();
  }

  stop() {
    return new Promise((resolve, reject) => {
      this.mediaRecorder.stop();
    });
  }
}

// export async function getFields() {
//   try {
//     const boardId = window.mondayContext.boardId;
//     const fieldQuery = `query {
//     boards (ids: ${boardId}) {
//       owner {
//         id
//       }
//       columns {
//         id
//         title
//         type
//       }
//     }
//   }`;
//     const fields = await monday.api(fieldQuery);
//     console.log("FIELDS", fields);
//     return fields.data.boards[0].columns.filter(
//       (column) => column.type === "file"
//     );
//   } catch (ex) {
//     return [];
//   }
// }

export const settings = {
  get: async () => {
    const settings = await monday.storage.instance.getItem("settings");
    if (!settings.data.value) {
      return null;
    }
    return JSON.parse(settings.data.value);
  },
  save: async (settings) => {
    return monday.storage.instance.setItem(
      "settings",
      JSON.stringify(settings)
    );
  },
};

export async function uploadFile(file, message) {
  const itemId = window.mondayContext.itemId;
  let updateId = undefined;
  if (window.appSettings.saveInUpdate) {
    let obj = await addFileToUpdate(itemId, file, message);
    updateId = obj.updateId;
  }
  const fields = window.appSettings.updateInFields;
  const fieldIds = fields && Object.keys(fields).filter((key) => !!fields[key]);
  await addFileToFields(itemId, fieldIds, file, message);
  return { updateId, itemId };
}

async function addFileToUpdate(itemId, file, message) {
  try {
    const createUpdateQuery = `mutation {
      create_update (item_id: ${itemId}, body: "${message}"){
        id
      }
    }`;
    const newUpdate = await monday.api(createUpdateQuery);
    const updateId = Number(newUpdate.data.create_update.id);
    const fileUploadQuery = `mutation add_file($update_id:Int!, $video: File!) {
      add_file_to_update(update_id: $update_id, file: $video) {
        id
      }
    }`;
    await monday.api(fileUploadQuery, {
      variables: {
        video: file,
        update_id: updateId,
      },
    });
    return { updateId, itemId };
  } catch (ex) {
    return { itemId };
  }
}

async function addFileToFields(itemId, fields, file) {
  try {
    for (let i = 0; i < fields.length; i++) {
      const fileUploadQuery = `mutation add_file($item_id:Int!, $field_id:String!, $media: File!) {
        add_file_to_column (item_id: $item_id, column_id: $field_id, file: $media) {
          id
        }
      }`;
      await monday.api(fileUploadQuery, {
        variables: {
          item_id: itemId,
          media: file,
          field_id: fields[i],
        },
      });
    }
    return { itemId };
  } catch (ex) {
    return { itemId };
  }
}

function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(",")[1]);
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export function blobToDataURI(blob) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      resolve(e.target.result);
    };
    fileReader.readAsDataURL(blob);
  });
}

export async function saveBlob(key, blob) {
  const dataURI = await blobToDataURI(blob);
  await db.files.put({
    id: key,
    file: dataURI,
  });
}

export async function saveDataURI(key, dataURI) {
  await db.files.put({
    id: key,
    file: dataURI,
  });
}

export async function readBlob(key) {
  const data = await db.files.where({ id: key }).first();
  const { file } = data;
  return dataURItoBlob(file);
}

export async function deleteBlob(key) {
  await db.files.delete(key);
}
