import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import mondaySdk from "monday-sdk-js";
import "monday-ui-react-core/dist/main.css";
//Explore more Monday React Components here: https://style.monday.com/
// import AttentionBox from "monday-ui-react-core/dist/AttentionBox.js";
import { Toolbar } from "./components/toolbar";
import { EditImage } from "./components/edit-image";
// import { Settings } from "./components/settings";
import { Docs } from "./components/docs";
import { Privacy } from "./components/privacy";

const monday = mondaySdk();

function App(props) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    monday.listen("context", (res) => {
      if (!res.data.itemId && res.data.itemIds && res.data.itemIds[0]) {
        res.data.itemId = res.data.itemIds[0];
      }
      if (!res.data.boardId && res.data.boardIds && res.data.boardIds[0]) {
        res.data.boardId = res.data.boardIds[0];
      }
      window.mondayContext = res.data;
      if (res.data.theme === "dark") {
        if (!document.body.classList.contains("dark-app-theme")) {
          document.body.classList.add("dark-app-theme");
        } else {
          document.body.classList.remove("dark-app-theme");
        }
      } else {
        document.body.classList.remove("dark-app-theme");
      }
      if (res.data.itemId) {
        setLoading(false);
      }
    });
    monday.listen("settings", (res) => {
      window.appSettings = res.data;
    });
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact={true}>
            {!loading && (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: "1 1 100%" }}>
                  {/* <Settings /> */}
                  <Toolbar />
                </div>
                <div
                  style={{
                    height: "40px",
                    display: "flex",
                    borderTop: "1px solid var(--ui-border-color)",
                    fontSize: "0.8em",
                  }}
                >
                  <a
                    href="https://monday-capture-screen.proj.me/docs"
                    target="_blank"
                    style={{ alignSelf: "center" }}
                    rel="noreferrer"
                  >
                    Help
                  </a>
                  <div style={{ flex: "1 1 100%" }}></div>
                  <a
                    href="https://monday-capture-screen.proj.me/privacy"
                    target="_blank"
                    style={{ alignSelf: "center" }}
                    rel="noreferrer"
                  >
                    Privacy
                  </a>
                </div>
              </div>
            )}
          </Route>
          <Route path="/edit/image/:imageId" exact={true}>
            <EditImage />
          </Route>
          <Route path="/docs" exact={true}>
            <div style={{ maxWidth: "600px", margin: "0 auto" }}>
              <h1>Capture Screen</h1>
              <Docs showCitation={true} />
            </div>
          </Route>
          <Route path="/privacy" exact={true}>
            <Privacy />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
