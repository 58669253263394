import React, { useEffect, useState } from "react";
import styles from "./preview.module.css";
import mondaySdk from "monday-sdk-js";

import { Button, TextField } from "monday-ui-react-core";
import { uploadFile, readBlob, deleteBlob } from "../../utils";
import { VideoPlayer } from "../videoplayer";

const monday = mondaySdk();

export function Preview({
  id,
  type,
  filename,
  successMessage,
  onProgress,
  onComplete,
}) {
  const [message, setMessage] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  useEffect(() => {
    (async function () {
      const blob = await readBlob(id);
      setPreviewUrl(URL.createObjectURL(blob));
    })();
    return () => {
      URL.revokeObjectURL(previewUrl);
    };
  }, []);
  return (
    <div className={styles.preview}>
      {type === "image" && previewUrl && (
        <div className={styles.imagebox}>
          <button
            className={styles.editbutton}
            onClick={() => {
              const editWindow = window.open(`/edit/image/${id}`);
              editWindow.onunload = async () => {
                const blob = await readBlob(id);
                setPreviewUrl(URL.createObjectURL(blob));
              };
            }}
          >
            Edit image
          </button>
          <img src={previewUrl} />
        </div>
      )}
      {type === "video" && previewUrl && (
        <div className={styles.videobox}>
          {/* <button
            className={styles.editbutton}
            onClick={() => {
              const editWindow = window.open(`/edit/video/${id}`);
              editWindow.onunload = async () => {
                const blob = await readBlob(id);
                setPreviewUrl(URL.createObjectURL(blob));
              };
            }}
          >
            Edit video
          </button> */}
          <VideoPlayer
            controls={true}
            sources={[
              {
                src: previewUrl,
                type: "video/mp4",
              },
            ]}
          />
        </div>
      )}
      <div className={styles.actions}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            onProgress("Uploading");
            const blob = await readBlob(id);
            const { updateId, itemId } = await uploadFile(
              new File([blob], filename),
              message
            );
            monday.execute("notice", {
              message: successMessage,
              type: "success",
            });
            if (updateId) {
              monday.execute("openItemCard", { itemId, kind: "updates" });
            } else {
              monday.execute("openItemCard", { itemId, kind: "columns" });
            }
            onComplete();
            await deleteBlob(id);
          }}
          style={{ width: "100%" }}
        >
          <TextField
            placeholder="Enter description here"
            size={TextField.sizes.MEDIUM}
            onChange={(value) => {
              setMessage(value);
            }}
          />
          <button>Save</button>
        </form>
      </div>
    </div>
  );
}
