import React from "react";
import { Button } from "monday-ui-react-core";
import Close from "monday-ui-react-core/dist/icons/Close";

import styles from "./closebutton.module.css";

export function CloseButton({ onClick }) {
  return (
    <Button
      size={Button.sizes.SMALL}
      kind={Button.kinds.TERTIARY}
      className={styles.closebutton}
      onClick={onClick}
    >
      <Close />
    </Button>
  );
}
