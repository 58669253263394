import React, { useEffect, useState } from "react";

import styles from "./countdown.module.css";

import audioFile from "./countdown.mp3";

export function CountDown({ time = 3, onComplete }) {
  const [currentTime, setCurrentTime] = useState(time);
  useEffect(() => {
    let inc = time - 1;
    const timer = setInterval(() => {
      if (inc === 0) {
        clearInterval(timer);
        setTimeout(() => {
          onComplete && onComplete();
        }, 1000);
      }
      setCurrentTime(inc--);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <>
      {currentTime > 0 && (
        <span className={styles.countdown}>{currentTime}</span>
      )}
      <audio src={audioFile} autoPlay={true} />
    </>
  );
}
