import React from "react";

export function Privacy() {
  return (
    <div className="privacy">
      <h1>Privacy Policy</h1>
      <span>
        We don't store your data, period. All your screenshots and recording are
        stored right in your browser and monday.com account.
      </span>
    </div>
  );
}
